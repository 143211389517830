import React, { useState } from 'react';

import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';
import { FcTwoSmartphones, FcFeedback, FcShop } from 'react-icons/fc'

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <h2 className="head-text">Contact <span>Us!</span></h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <FcFeedback size={ 50 } alt="email" />
          <a href="mailto:legacy@thapuwealth.co.za" className="p-text">legacy@thapuwealth.co.za</a>
        </div>
        <div className="app__footer-card">
          <FcShop size={ 50 } alt="address" />
          <a href="https://www.google.com/search?q=41+Sloane+Street%2C+Bryanston%2C+Johannesburg&sca_esv=580797621&rlz=1C1GCEU_en-GBZA1044ZA1044&sxsrf=AM9HkKnFA4DH8Er5nTmO30NTsDJb6zrYeA%3A1699520773453&ei=BaFMZe-nG5qlhbIPrc68kAY&ved=0ahUKEwivvvWfyLaCAxWaUkEAHS0nD2IQ4dUDCBA&uact=5&oq=41+Sloane+Street%2C+Bryanston%2C+Johannesburg&gs_lp=Egxnd3Mtd2l6LXNlcnAiKTQxIFNsb2FuZSBTdHJlZXQsIEJyeWFuc3RvbiwgSm9oYW5uZXNidXJnMgIQJkjXhAFQoAlYqEZwAngAkAEAmAHRA6ABqkKqAQgyLTIzLjMuM7gBA8gBAPgBAcICBxAjGLADGCfCAggQABiABBiwA8ICBxAAGB4YsAPCAgQQIxgnwgIFEAAYgATCAgYQABgWGB7iAwQYASBBiAYBkAYE&sclient=gws-wiz-serp" className="p-text">41 Sloane Street, Bryanston, Johannesburg, 2191</a>
        </div>
        <div className="app__footer-card">
          <FcTwoSmartphones size={ 50 } alt="phone" />
          <a href="tel:+27 (011) 054-2531" className="p-text">+27 11 054 2531</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);